<template>
  <div
    class="page__login-inputComp"
    :class="styleClass"
  >
    <div class="page__login-inputComp_wrapper">
      <label for="">{{ label }} <slot name="label-icon"></slot></label>
      <div class="page__login-inputComp_con">
        <div
          v-if="$slots.prev"
          class="page__login-inputComp_con-prev"
        >
          <slot name="prev"></slot>
        </div>
        <div
          v-if="type != 'password'"
          class="page__login-inputComp_con-input"
        >
          <input
            :disabled="disabled"
            :type="type"
            :value="modelValue"
            @input="handleInput"
            @click="handleClick"
            @blur="handleBlur"
            @focus="handleFocus"
          />
        </div>
        <div
          v-if="type == 'password'"
          class="page__login-inputComp_con-input"
        >
          <input
            v-show="showPassword"
            type="text"
            :value="modelValue"
            @input="handleInput"
            @click="handleClick"
            @blur="handleBlur"
            @focus="handleFocus"
          />
          <input
            v-show="!showPassword"
            type="password"
            :value="modelValue"
            @input="handleInput"
            @click="handleClick"
            @blur="handleBlur"
            @focus="handleFocus"
          />
        </div>
        <div
          class="page__login-inputComp_con-rightIcon"
        >
          <i
            v-if="clearable"
            v-show="isFoucus"
            class="suiiconfont sui_icon_cleanall_16px icon-clear"
            @click="handleClear"
          ></i>
          <i
            v-if="type == 'password'"
            class="suiiconfont icon-eye"
            :class="showPassword ? 'sui_icon_eyeopen_20px' : 'sui_icon_eyeclosed_20px' "
            @click="handleEyeBtn"
          ></i>
        </div>
        <div
          v-if="$slots.end"
          class="page__login-inputComp_con-end"
        >
          <slot name="end"></slot>
        </div>
      </div>
      <ClientOnly>
        <template v-if="isLoadInput">
          <EmailRecommendInput
            ref="emailRecommendRef"
            :model-value="modelValue"
            :is-focus="isFoucus"
            :forbidden-recommend="isForbiddenRecommend"
            @update:model-value="handleSelectRecommendEmail"
          />
        </template>
      </ClientOnly>
    </div>   
    <div
      class="page__login-inputComp_tips"
      v-html="tips"
    ></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import EmailRecommendInput from '../EmailRecommendInput/index.vue'
import { sleep } from '../../util'
import { ClientOnly } from '@sheinfe/vue-client-only'

export default defineComponent({
  name: 'LoginInput',
  components: {
    EmailRecommendInput,
    ClientOnly
  },
  props: {
    disabled: { type: Boolean, default: false },
    modelValue: { type: String, default: '' },
    label: { type: String, default: '' },
    tips: { type: String, default: '' },
    type: { type: String, default: 'text' },
    clearable: { type: Boolean, default: true },
    mode: { type: String, default: '' },
    uiType: { type: String, default: '' },
  },
  data: () => ({
    isLoadInput: false,
    isFoucus: false,
    showPassword: false,
  }),
  computed: {
    styleClass () {
      return {
        'page__login-inputCompNew': this.uiType == 'new',
        'page__login-inputComp_focus': this.isFoucus,
        'page__login-inputComp_inputed': this.modelValue.length,
        'page__login-inputComp_error': this.tips.length,
        'page__login-inputComp_disabled': this.disabled,
      }
    },
    isForbiddenRecommend () {
      const { disabled, type, mode } = this
      return mode !== 'email' || type === 'password' || !!disabled
    }
  },
  methods: {
    handleFocus () {
      this.isLoadInput = true
      this.isFoucus = true
      this.$emit('focus')
    },
    handleInput (e) {
      this.isFoucus = true
      this.$emit('update:model-value', e.target.value)
    },
    handleClear () {
      this.$emit('clear')
      this.$emit('update:model-value', '')
      this.isFoucus = true
    },
    handleClick () {
      this.isFoucus = true
      this.$emit('click')
    },
    async handleBlur () {
      await sleep(100)
      this.isFoucus = false
      this.$emit('blur')
    },
    handleEyeBtn () {
      this.showPassword = !this.showPassword
    },
    handleSelectRecommendEmail (email) {
      this.$emit('update:model-value', email)
    }
  }
})
</script>

<style lang="less">
.page__login-inputComp {
    &_wrapper {
        padding-top: 16px;
        position: relative;
        border-bottom: 1px solid #E5E5E5;
    }
    label {
        color: #959595;
        position: absolute;
        font-size: 14px;
        line-height: 1;
        top: 18px;
        .left(0);
        z-index: @zindex-zero;
        transition: all ease-in-out 0.2s;
    }
    &_tips {
        color: @sui_color_unusual;
        padding: .12rem 0;
        display: none;
    }
    &_con {
        display: flex;
        height: 40px;
        line-height: 40px;
        position: relative;
        z-index: @zindex-hack;
        box-sizing: border-box;
        .icon-clear {
            padding: 0 5px;
        }
        .icon-eye {
            padding: 0 3px;
        }
    }
    &_con-rightIcon {
        display: flex;
    }
    &_con-input {
        flex: 1 1 100%;
        input {
            width: 100%;
            height: 100%;
            border: 0;
            background: transparent;
            color: #222;
            font-size: 14px;
            font-weight: bold;
        }
        input:disabled {
            color: #666;
        }
    }
    &_focus, &_inputed {
        label {
            top: 0px;
            // font-size: 12px;
        }
    }
    &_focus {
        .page__login-inputComp_wrapper {
            border-color: #222;
        }
    }
    &_error {
        .page__login-inputComp_wrapper {
            border-bottom: 1px solid @sui_color_unusual;
        }
        .page__login-inputComp_tips {
            display: block;
        }
    }
}

.page__login-inputCompNew {
  label {
    display: block;
    position: relative;
    transition: none;
    top: 0;
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 8px;
  }
  &.page__login-inputComp_focus, &.page__login-inputComp_inputed {
    .page__login-inputComp_con {
      border-color: #222;
    }
  }
  .page__login-inputComp_wrapper {
    border-bottom: 0;
  }
  .page__login-inputComp_con {
      border: 1px solid @sui_color_gray_light2;
      padding: 0 12px;
      .icon-clear {
          padding: 0 5px;
      }
      .icon-eye {
          padding: 0 3px;
      }
  }
}

.page__login-inputComp_con-rightIcon {
  .suiiconfont {
      color: #959595;
      .margin-r(4px);
    }
}
</style>
