import schttp from 'public/src/services/schttp/index'

function requestPromise(type, url, params, oheaders = {}, options = {}) {
  const headers = { 'x-ftoken': window.gbCommonInfo.ftoken, ...oheaders }
  const method = type.toLowerCase()
  const soptions = { method: type, url, headers, ...options }
  if (method == 'get') soptions.params = params
  else soptions.data = params

  return schttp(soptions).catch((err) => ({ code: -1, msg: err.message }))
}

export async function request(type, url, params, headers = {}, options = {}) {
  const data = await requestPromise(type, url, params, headers, options)
  return data
}
